import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
//import logo from './logo.svg';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//https://github.com/duskload/react-device-detect/blob/HEAD/docs/views.md
import { browserName, browserVersion } from "react-device-detect";
import { BrowserView, TabletView, MobileView, AndroidView, IOSView, isBrowser, isMobile } from 'react-device-detect';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Placeholder from 'react-bootstrap/Placeholder';
import AppMenu from '../AppMenu';

//import Menu from './components/Menu';
//import Item from './components/Item';

const axios = require('axios').default;

function Menu() {
    const [show, setShow] = useState(false);
    const [placeShow, setPlaceShow] = useState(false);
    const [id, setID] = useState('');
    const [ar, setAR] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const itemData = [
        { id: '1', name: 'Salad', image: 'salad.jpg', desc: 'An amazing salad..', price: '14', allergy: 'fish' },
        { id: '2', name: 'Burger', image: 'salad.jpg', desc: 'An amazing burger..', price: '12', allergy: 'nuts' },
        { id: '3', name: 'Mount Nacho', image: 'salad.jpg', desc: 'An amazing nacho plate..', price: '21', allergy: 'nuts' }
    ]

    useEffect(() => {
        checkAR();
        //setARInteract(id);

    }, [ar])




    ///
    function getUser() {
        // Optionally the request above could also be done as
        axios({
            method: 'get',
            url: 'http://bit.ly/2mTM3nY',
            responseType: 'stream'
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }


    function postUser() {
        // Send a POST request
        axios({
            method: 'post',
            url: '/user/12345',
            data: {
                firstName: 'Fred',
                lastName: 'Flintstone'
            }
        }).then(function (response) {
            console.log(response);
        })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    };

    function checkAR() {
        const a = document.createElement("a");
        if (a.relList.supports("ar")) {
            // AR is available.
            console.log('AR is available.')
            setAR('Device Supports AR!');
        } else {
            console.log('AR is NOT available.')
            setAR('Device Does Not Supports AR!');
        }
    }

    function setARInteract(CTAid,event) {


        //https://developer.apple.com/documentation/arkit/adding_an_apple_pay_button_or_a_custom_action_in_ar_quick_look
        const linkElement = document.getElementById("ar-link");
        linkElement.addEventListener("message", function (event) {
            alert('AR TAPPED')
        }, false);

        /*
        const linkElement = document.getElementById("ar-link");
        linkElement.addEventListener("message", function (event) {
            if (event.data == "_apple_ar_quicklook_button_tapped") {
                // handle the user tap.   
            }
        }, false);
        */
    }

    return (

        <div>
            <AppMenu />
            <ListGroup as="ol">
                {itemData.map((data, key) => {
                    return (
                        <ListGroup.Item
                            key={key}
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                            onClick={() => { handleShow(); setID(data.id); }}
                        >
                            <img src={data.image} width='100px' />
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{data.name}</div>
                                {data.desc}
                            </div>
                            <img src='ar.png' width='100px' />

                            <Badge variant="primary" pill>
                                $ {data.price}
                            </Badge>

                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            <BrowserView>
                <h1>This is rendered only in browser</h1>
            </BrowserView>
            <TabletView>
                <h1>This is rendered only on tablet</h1>
            </TabletView>
            <MobileView>
                <h1>This is rendered only on mobile</h1>
            </MobileView>
            <AndroidView>
                <h1>This is rendered only on Android</h1>
            </AndroidView>
            <IOSView>
                <h1>This is rendered only on IOS</h1>
            </IOSView>


            <Offcanvas
                show={show}
                placement="end"
                scroll='false'
                backdrop='true'
                onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <img src='ar.png' width='100px' />

                        Offcanvas for ID:{id}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {/*CTA STYLE */}
                    <a id='ar-link' href="mask.usdz#checkoutTitle=Biplane%20Toy&checkoutSubtitle=Rustic%20finish%20with%20rotating%20propeller&price=$15&callToAction=Add%20to%20cart" rel="ar">
                        <img src='salad.jpg' width='100%' />
                        CTA
                    </a>
                    {/*CAMERA SOCIAL SHARE STYLE */}
                    <a id='ar-link' href="mask.usdz#canonicalWebPageURL=https://www.vizcache.com" rel="ar">
                        <img src='salad.jpg' width='100%' />
                        CANONICAL CAMERA
                    </a>
                    {ar}
                    <hr />

                    {browserName}, {browserVersion}
                    Some text as placeholder. In real life you can have the elements you
                    have chosen. Like, text, images, lists, etc.
                    <Placeholder visibility={placeShow} as={Offcanvas.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder visibility={placeShow} as={Offcanvas.Text} animation="glow">
                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                        <Placeholder xs={6} /> <Placeholder xs={8} />
                    </Placeholder>
                    <Button>Add to Order</Button>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default Menu;
