import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';

import Menu from './components/Menu';
import Server from './components/Server';
import Cooks from './components/Cooks';
import Order from './components/Order';
import MenuManager from './components/MenuManager';
import Checkin from './components/Checkin';
import Item from './components/Item';

import QrReader from 'react-qr-reader'

const axios = require('axios').default;

function App() {
  const [result, setResult] = useState([]);

  const data = {
    1: '0xcB28fe5908C5e8d8E842830320bBc57d9B56b0A1',
    2: '0x200a589A529DCCEAb074B51E0Ba9C5D89b199Ccd',
  }

  function getUser() {
    // Optionally the request above could also be done as
    axios({
      method: 'get',
      url: 'http://bit.ly/2mTM3nY',
      responseType: 'stream'
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  function postUser() {
    // Send a POST request
    axios({
      method: 'post',
      url: '/user/12345',
      data: {
        firstName: 'Fred',
        lastName: 'Flintstone'
      }
    }).then(function (response) {
      console.log(response);
    })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  function parseURL(data) {
    /*
    let payload = data.slice(21);
    var mySubString = payload.split('/');
    setId(mySubString[1]);
    setContract(mySubString[0]);
    console.log(mySubString);
    return mySubString
    */
  }

  function handleScan(data) {
    if (data) {
      setResult(
        data
      )
      //Parse QRCode URL
      //Call Api to Display button.
      //let id = data.slice(64);
      //let contract = data.slice(21,63);
      //let urlParts = parseURL(data);


      //loadApi(urlParts[0], urlParts[1]);

      //OR REDIRECT
      //window.location.href = data;
      //history.push("/" + data.slice(21));

    }
  }
  function handleError(err) {
    console.error(err)
  }

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route key="1" exact path="/"> <Home data={data}></Home></Route>
          <Route key="2" exact path="/menu"><Menu data={data} /></Route>
          <Route key="3" exact path="/server"> <Server data={data} /></Route>
          <Route key="4" exact path="/cooks"> <Cooks data={data} /></Route>
          <Route key="5" exact path="/order"> <Order data={data} /></Route>
          <Route key="6" exact path="/MenuManager"> <MenuManager data={data} /></Route>
          <Route key="7" exact path="/item"> <Item data={data} /></Route>
          <Route key="8" exact path="/checkin"> <Checkin data={data} /></Route>
        </Switch>
      </div>
      <div>
        <div className='text-light'>
          Scanning...
          {result}
        </div>
      </div>

    </Router>

  );
}

export default App;

function Home() {

  return (
    <div>
      <header className="App-header">
        <img src='ar.png' className="App-logo" alt="logo" />
        <h1>
          Premium Augmented Menus
        </h1>
        <p>An elegant and simple fine dining experience</p>
        <Link className='btn btn-primary' to="/menu">
          Menu
        </Link>
        <Link className='btn btn-primary' to="/checkin">
          Checkin
        </Link>
        
      </header>
    </div>
  )
}


/*
      <QrReader
        //resolution={1000}
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}


      />
      */
