import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
//import logo from './logo.svg';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//https://github.com/duskload/react-device-detect/blob/HEAD/docs/views.md
import { browserName, browserVersion } from "react-device-detect";
import { BrowserView, TabletView, MobileView, AndroidView, IOSView, isBrowser, isMobile } from 'react-device-detect';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Placeholder from 'react-bootstrap/Placeholder';

//import Menu from './components/Menu';
//import Item from './components/Item';
import AppMenu from '../AppMenu';


const axios = require('axios').default;

function Cooks() {
    const [show, setShow] = useState(false);
    const [placeShow, setPlaceShow] = useState(false);
    const [id, setID] = useState('');
    const [itemId, setItemID] = useState('');
    const [items, setItems] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const orderData = [
        {
            id: '1', name: 'Order 1', table: '1', desc: 'An amazing salad..', total: '14', priority: '1', items: [
                { itemId: '1', menuId: '1', itemName: 'Chicken Curry', notes: 'please make spicy', status: 'commited' },
                { itemId: '2', menuId: '37', itemName: 'Chicken Curry', notes: 'please make spicy,!peanut allergy!', status: 'ordered' },
                { itemId: '3', menuId: '37', itemName: 'Chicken Curry', notes: 'please make spicy,!peanut allergy!', status: 'finished,at table' },
            ]
        },
        {
            id: '2', name: 'Order 2', table: '2', desc: 'An amazing burger..', total: '12', priority: '3', items: [
                { itemId: '1', menuId: '1', itemName: 'Chicken Curry', notes: 'please make spicy', status: 'commited' },
                { itemId: '2', menuId: '37', itemName: 'Chicken Curry', notes: 'please make spicy,!peanut allergy!', status: 'ordered' },
                { itemId: '3', menuId: '37', itemName: 'Chicken Curry', notes: 'please make spicy,!peanut allergy!', status: 'finished,at table' },
            ]
        },
        {
            id: '3', name: 'Order 3', table: '3', desc: 'An amazing nacho plate..', total: '21', priority: '5', items: [
                { itemId: '1', menuId: '1', itemName: 'Chicken Curry', notes: 'please make spicy', status: 'commited' },
                { itemId: '2', menuId: '37', itemName: 'Chicken Curry', notes: 'please make spicy,!peanut allergy!', status: 'ordered' },
                { itemId: '3', menuId: '37', itemName: 'Chicken Curry', notes: 'please make spicy,!peanut allergy!', status: 'finished,at table' },
            ]
        },
    ]

    function getOrderItem(id) {
        let items = orderData[id].items;
        setItems(items);
    }



    ///
    function getUser() {
        // Optionally the request above could also be done as


        axios({
            method: 'get',
            url: 'http://bit.ly/2mTM3nY',
            responseType: 'stream'
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    function postUser() {
        // Send a POST request
        axios({
            method: 'post',
            url: '/user/12345',
            data: {
                firstName: 'Fred',
                lastName: 'Flintstone'
            }
        }).then(function (response) {
            console.log(response);
        })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    };

    return (

        <div >
            <AppMenu />
            <h2>Active Orders</h2>
            <ListGroup as="ol">
                {orderData.map((data, key) => {
                    return (
                        <ListGroup.Item
                            key={key}
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                            onClick={() => { handleShow(); setID(data.id); getOrderItem(data.id) }}
                        >
                            <h2>Table {data.table}</h2>
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{data.name}|{data.id}</div>

                            </div>


                            <Badge variant="primary" pill>
                                Priority {data.priority}
                            </Badge>

                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            <h2>Completed Orders</h2>
            <ListGroup as="ol">
                {orderData.map((data, key) => {
                    return (
                        <ListGroup.Item
                            key={key}
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                            onClick={() => { handleShow(); setID(data.id); getOrderItem(data.id) }}
                        >
                            <h2>Table {data.table}</h2>
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{data.name}|{data.id}</div>

                            </div>


                            <Badge variant="primary" pill>
                                Priority {data.priority}
                            </Badge>

                        </ListGroup.Item>
                    )
                })}
            </ListGroup>


            <BrowserView>
                <Offcanvas
                    show={show}
                    placement="end"
                    scroll='false'
                    backdrop='true'
                    onHide={handleClose}
                    style={{ width: '70vw' }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            Order ID:{id}
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Button onClick={handleClose}>Back</Button>
                        <Button>Begin Order</Button>
                        <Button>ReMake</Button>
                        <ListGroup as="ol">
                            {items.map((data, key) => {
                                return (
                                    <ListGroup.Item
                                        key={key}
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"

                                    >
                                        <h2>{data.itemId}</h2>
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">{data.itemName}</div>
                                            {data.notes}
                                        </div>


                                        <Badge variant="primary" pill>
                                            Priority {data.status}
                                        </Badge>

                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                        <Button>Order Complete | Ready for Server Pickup</Button>
                        <Button>Order Arrived</Button>


                        {browserName}, {browserVersion}
                        Some text as placeholder. In real life you can have the elements you
                        have chosen. Like, text, images, lists, etc.

                    </Offcanvas.Body>
                </Offcanvas>
                <h1>This is rendered only in browser</h1>

            </BrowserView>
            <TabletView>
                <h1>This is rendered only on tablet</h1>
            </TabletView>
            <MobileView>
            <Offcanvas
                show={show}
                placement="end"
                scroll='false'
                backdrop='true'
                onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Order ID:{id}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Button onClick={handleClose}>Back</Button>
                    <Button>Begin Order</Button>
                    <Button>ReMake</Button>
                    <ListGroup as="ol">
                        {items.map((data, key) => {
                            return (
                                <ListGroup.Item
                                    key={key}
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"

                                >
                                    <h2>{data.itemId}</h2>
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{data.itemName}</div>
                                        {data.notes}
                                    </div>


                                    <Badge variant="primary" pill>
                                        Priority {data.status}
                                    </Badge>

                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                    <Button>Order Complete | Ready for Server Pickup</Button>
                    <Button>Order Arrived</Button>


                    {browserName}, {browserVersion}
                    Some text as placeholder. In real life you can have the elements you
                    have chosen. Like, text, images, lists, etc.

                </Offcanvas.Body>
            </Offcanvas>
                <h1>This is rendered only on mobile</h1>
            </MobileView>
            <AndroidView>
                <h1>This is rendered only on Android</h1>
            </AndroidView>
            <IOSView>
                <h1>This is rendered only on IOS</h1>
            </IOSView>



        </div>
    );
}

export default Cooks;
