import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
//import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//https://github.com/duskload/react-device-detect/blob/HEAD/docs/views.md
import { browserName, browserVersion } from "react-device-detect";
import { BrowserView, TabletView, MobileView, AndroidView, IOSView, isBrowser, isMobile } from 'react-device-detect';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Placeholder from 'react-bootstrap/Placeholder';


function AppMenu() {
    const [result, setResult] = useState([]);


    return (

        <Navbar bg="light" expand="lg" sticky="top">
            <Navbar.Brand href="/">

                <img src='./Vizcache2_web.png' height='50px' style={{ margin: 10 }} className='pr-4'></img>
                Restaurant Name
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end mr-2">
                <Nav className="container-fluid" >
                    <Nav.Item className="mr-auto">
                        <Nav.Link as={Link} to='/menu' > Specials</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mr-auto">
                        <Nav.Link as={Link} to='/menu' > Mains</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mr-auto">
                        <Nav.Link as={Link} to='/menu' > Seconds</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mr-auto">
                        <Nav.Link as={Link} to='/menu' > Fish</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mr-auto">
                        <Nav.Link as={Link} to='/menu' > Meat</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mr-auto">
                        <Nav.Link as={Link} to='/menu' > Desert</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mr-auto">
                        <Nav.Link as={Link} to='/menu' > Beverages</Nav.Link>
                    </Nav.Item>
                    <NavDropdown title='Tools' id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to='/server' > Server</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/cooks' > Cooks</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/order' > Order</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/MenuManager' > Menu Manager</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/supply' > Supply</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Item className="ml-auto">
                        <Nav.Link as={Link} to='/checkin' > COVID Check In</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    )
}
export default AppMenu;